/*Variables*/
$blue: #00BCD4;
$grey: #EDF7F2;

.titleListDoc{
    text-align: center;
}

.row{
    z-index: 220 ;
    position : relative;
}
.list{
    text-align: center;
  
}

.addButton{
    margin-left: 48%;
}

.iconEdit{
    cursor: pointer;
    position: relative;
}


.search{
    margin-left: 80%;
}


/* .searchNew{
  //display: grid;
  //justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: -1;
} */

.contentButton{
    //padding-left : 4%;
    padding-top : 20px;
    position : relative;
    // margin-left: 30%;
   // grid-column: 3;    
}

// .search{
//   //  grid-column : 1;   
// }

.contentMenu{
    display: flex;
    //justify-content : space-between;
}

.iconEdit{
    margin-left: 28%;
    margin-top : 5px;
}

.listDz{
    position: relative;
    :global(.dropzone.dz-drag-hover){
        z-index: 9999 !important;
        background-color: rgba(0, 188, 212, 0.1) !important;
        border: 2px dotted rgb(0, 188, 212) !important;
        overflow-x :hidden !important;    
    }
    
    :global(.filepicker-file-icon){
        display: none;
    }

    :global(.filepicker) {
        border: 0 !important;
        position: absolute !important;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0) !important;
        overflow-x :hidden !important;
        z-index : 200;
    }

    :global(.dz-message) {
        display: none;
    }
}

:global(.form-control){
    z-index: 999 !important;
    position : relative;
}

// :global(input){
//     z-index: 999 !important;
//     position : relative;
// }

:global(.glyphicon-file){
    z-index: 999 !important;
    position : relative;
}

.iconEdit{
    z-index: 999 !important;
    position : relative !important;
}

:global(.pagination){
    z-index: 999 !important;
    position : relative;
}

:global(.dropdown){
    z-index: 999 !important;
    position : relative;
}
//Gère l'emplcement de la pagination de la liste 
// :global(.react-bs-table-pagination){
//     margin-left : 10%;
// }


// :global(.dropdown){
//     display: none;
// }


/*IE 11*/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
/* IE10+ CSS styles go here */
//bouton nouveau inaccessible avec dropzone
    .contentButton{
        position : relative;
    }
}
       
    //enlève la dropdown de la pagination
    // :global(.dropdown) {
    //     display: none;
    // }       

    .contentIcon{
        display: none;
    }

    // :global(.order::after){        
    //     font-family: 'Font Awesome\ 5 Free';
    //     content : '\F0DC'; 
    //     font-size : 10px;
    //     margin-left: 6px;
    //     position : relative;
    //     z-index: 999;
    //     font-size : 13px;
    // }

    :global(.page-link ) {
        color:#212529 !important;
        background-color:rgba(255, 255, 255, 0) !important;
    }

    :global(.active) {
        :global(.page-link){
            font-weight:bold !important;
            border-color:#dee2e6!important;
        }

    }

    // :global(.react-bs-table-tool-bar){
    //     margin-bottom:25px !important;
    // }


   .itemMenuParameter {
    padding-left : 20px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;        
    transition: all 0.2s ease-in-out;
}
.itemMenuParameter:hover, .itemMenuParameterVersion:hover  {
    background: rgba(112, 112, 112, 0.4);
}

.itemMenuParameterVersion{
    padding-left : 35px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;        
    transition: all 0.2s ease-in-out;
}

.iconPen{
    font-size: 20px !important;
    color: black !important;
    cursor: pointer;
}

.iconContainer{
    z-index: 220;
    position: relative;
    display: flex;
    justify-content: space-around;
}


// .groupInput{
//     z-index:99999999;
// }

// .contentList{
//     z-index:99999999 !important;
// }

// :global(#menu-) { // TODO VOIR STEVEN
//     z-index: 99999999999999 !important;
// }

.selectoverride{
    color : white !important;
}

.groupInput{
    color : white;
}

// .contentList{
//     color: white;
//     z-index: 999999999999999!important;
// }