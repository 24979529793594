$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-md-max: 768px;
 @mixin xs {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
 }
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 $screen-sm-max: 375px;
 @mixin xxs {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
 }
  
.fabProgress {
    position: 'absolute';
    top: 50%;
    left: 50%;
    margin-top: -12;
    margin-left: -12;
}
.recherche {
    .rechercheText {
        width: 100%;
    }
  }
  .filterDossier{
    padding: 0.9375rem 20px;
  }
  .checkFilter{
      display: grid;
      margin-left: 23vw;
      margin-top: -4vw;
      @include md {
        display: flex;
    }
  }

.firstContent{
color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 50px;
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 2%;
}
.secondContent{
    color: rgba(0, 0, 0, 0.87);
      width: 100%;
      border: 0;
      display: flex;
      position: relative;
      font-size: .875rem;
      min-width: 0;
      background: #FFF;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
      margin-top: 30px;
      border-radius: 6px;
      flex-direction: column;
      margin-bottom: 1%;
      padding: 1%;
    }

.smallContent{
color: rgba(0, 0, 0, 0.87);
  border: 0;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 20px !important;
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 3% !important;
//   margin-right: 10px !important;
}
.secondTitle {
    display: flex;
    color: #FFF !important;
  //  padding: 15px;
    padding-left : 10px;
    margin-top: -20px;
    border-radius: 3px;
    margin-right: 1vw;
   // padding-bottom: 6px;
    margin-bottom : 11px;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
}
.firstTitle {
    display: flex;
    color: #FFF !important;
    padding: 15px;
    margin-top: -20px;
    border-radius: 3px;
    margin-right: 1vw;
    margin-bottom : 11px;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
}
.filterContent{
    padding: 0.9375rem 20px;
    padding-bottom: 1%;
    width:100%; 
    @include md {
        padding: 0.9375rem 20px;
    padding-bottom: 1%;
    width:40%; 
    }
}

.titleBlock{
    text-align: center;
    padding: 1%;
    height:45px;
    border-radius: 3px;
    margin-top: 1vw;
    margin-bottom: 1vw;
    h5, h6{
        color: white;
    }
}

.titleBlockDossier {
    text-align: center;
    padding: 1%;
    height:45px;
    border-radius: 3px;
    margin-top: 1vw;
    margin-bottom: 1vw;
    h5, h6{
        color: white;
    }
    span{
        color: white !important;
        padding-left: 5px !important;
    }
}
.filter {
    margin-top: 12px;
    margin-right: 23px;
    margin-left: 23px;
    margin-bottom: 12px;
    .date{
        margin-top: 10px !important;
    }
}

.contentDate {
    margin-left : 23px;
    margin-bottom : 35px;
    margin-right: 23px;
    margin-top : 30px;
    .stylesSelect{
        margin-bottom : 11px;
    }
    .textfieldDate{
        margin-top : 20px;
        .date {
          width: 100%;
        }
    }
}

.textfieldDate{
    margin-top : 10px;
}

:global(.MuiTypography-h5){
    padding-top : 10px;
    width: 100%;
    text-align: center;
}
.contentModal{
 margin-top : 20px;
//  margin-bottom : 32px;
 margin-left : 5px;
 margin-right : 5px;
 padding-left: 5px;
 padding-right: 5px;

  .groupInput {
    width: 100%;
  }
}

.criters{
    padding: 10px 0 0 10px;

    .criterIndice{
      padding: 6px 10px;
      font-size: 12px;
      line-height: 14px;
  }
}
.contentButton{
  padding-left : 10px;
  padding-bottom : 10px;
  display: flex;
  justify-content: space-around;
}
.btnAssocie {
    margin-right: 5px !important;
    @include xs {
    margin-right: auto !important;
    margin-left: auto !important;
    bottom: 5px !important;
    }
    width: 20vh;
}

.btValide{
    margin-left :25px !important;
}

.allFilter {
display: flex;
}

.contentIcon{
    display: flex;
}

.iconContainer{
    display : flex;
}

.defaultText{
  margin-top: 10px;
}

.stylesBold{
    color : black;
}

.stylesGrey{
    color : grey;
}

.itemDisabled{
    cursor : not-allowed;
}

.icon{
    cursor : pointer;
}

.contentBtnDel{
    margin-bottom: 10px;
    margin-left : 33%;
}

.modalDltBanette{
    margin-top : 15px;
    margin-bottom : 18px;
    text-align : center;
    font-size: 17px;
    margin-left: 30px;
    margin-right: 30px;
}

// :global(.react-bs-table-tool-bar ){
//     margin : 0px !important;
// }
.contentBtnSearch{
    display: flex;
}

.buttonOk{
    margin-left: 10px !important;
}

.results{
  position:absolute;
  margin-top : 15px;
}

.taskBanettes{ // Comme celui de taskSlider_styles
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  margin-top: 0.25%;
  flex-direction: column;
  margin-bottom: 1%;
  @include md {
    margin-bottom: 2%;
  }
  @include xs {
    margin-bottom: 4%;
  }
  @include xxs {
    margin-bottom: 8%;
  }
  :global(.slick-next) {
    right: -20px;
    }
    :global(.slick-prev) {
      left: -10px;
      }
    :global(.slick-next::before) {
        color:black;
        }
        :global(.slick-prev::before) {
          color:black;
          }
      .sliderContentBanettes{
        flex: 1 1 auto;
        padding-left: 0.9375rem;
        position: relative;
        -webkit-box-flex: 1;
        padding-bottom: 0%;

        :global(.slick-list) {
          display: flex;
          width: 100%;
          position: relative;
        }
        :global(.slick-slide){
          margin-right: 1.5%;
          margin-top: 1.5%;
          @include md{
            margin-top: 2%;
          }
        }
        :global(.slick-track) {
          transform: translate3d(0px, 0px, 0px) !important;
          }
        :global(.slick-track) {
          display: flex;
        }
        :global(.slick-dots) {
          @include xs {
            margin: revert;
          }
        }

        .taskContainer{
          min-width: 125px;
          min-height: 50px;
          @include xxs {
            span {
              min-height: 75px;
            }
          }
          cursor:pointer;
          padding:10px;
          margin:5px;
          background: #e9ecef;
          border-radius: 5px;
          .sizeIcon{
            font-size : 12px;
            margin-right : 7px;
            margin-top : 3px;
            color : grey;
          }
          .contentDescr{
            display: flex;
            [aria-hidden="true"] {
              display: block !important;
              @include xxs {
                display: none !important;
              }
            }
          }
          .date{
            font-size : 11px;
            text-align : right;
          }
        }
      }
}
.trBootstrapTable{
  cursor: pointer;
}
.radioGroup{
  flex-direction: row !important;
  width: 100%;
  justify-content: space-around;
  label{
    width: max-content;
  }
}
.formLabel{
  font-size: 1.1rem !important;
  color: rgba($color: #000000, $alpha: 0.8) !important;
  width: 14.3rem;
  padding: 0.75em !important;
  margin: none !important;
}
.isPublic{
  display: flex;
  justify-content: space-between;
}
.formTitle{
  font-size: 1.5rem !important;
  color: rgba($color: #000000, $alpha: 0.87) !important;
  text-align: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.typeAffichage{
  display: flex;
  justify-content: space-between;
}
.showGroup{
  display: flex;
}
.groupIcon{
  margin-left: 5px;
}

.saveSearchModal {
  .modalSearchName{
    padding: 0 24px 16px 24px;

    .nameInput {
      width: 100%;
      margin-bottom: 15px;
    }

    .typeSearch {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .typeSearchLabel {
        padding-right: 5px;
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .buttonValide {
        margin-left: 10px;
      }
    }
  }
}
.listDisplay{
  margin-top: -20px;
}
.groupInputLabelzindex label{
  z-index: initial !important;
}