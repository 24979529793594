.orientationContainer {
  width:100%;
  .buttonContainer{
    width:100%;
    text-align:center;
    .customButton{
      background-color:blue;
      margin: 5px 0 5px 0;
      text-transform: none !important;
    }
  }
  
  .iconCards {
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 70%;
    text-align: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  .modelOrientation {
    border-bottom: 1px solid #c0c2c4;
    cursor: pointer;
    padding-top: 4px;
    height: 30px;

    &:hover {
      background-color: #e9ecef;
    }
  }
  .cardsContainer {
    padding-left: 5px;
    display: flex;
    height: 104px;
  }
}
.formControlModelSelect {
  min-width: 200px !important;
  :global(.selected) {
    background-color: rgb(202, 255, 202);
  }
}
.customButtonDialogValid{
  margin: 5px 0 5px 0;
  text-transform: none !important;
}
.customButtonDialogCancel{
  margin: 5px 0 5px 0;
  text-transform: none !important;
}
.gridContainer{
  .iconCards {
    cursor: pointer;
    width: 100%;
    height: 70%;
    text-align: center;
    padding-top: 13px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  .modelOrientation {
    border-bottom: 1px solid #c0c2c4;
    cursor: pointer;
    padding-top: 4px;
    height: 30px;

    &:hover {
      background-color: #e9ecef;
    }
  }
  .cardsContainer {
    padding-left: 5px;
    display: flex;
    height: 104px;
    margin: 2px;
  }
}