$screen-md-max: 768px;

@mixin xs {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

.iconAction {
    cursor: pointer;
}

.listActionContainer {
    display: flex;
    justify-content: space-around;
}

.contentStep {
    display: flex;
    justify-content: space-between;
    background-color: #265e66;
    align-items: center;
    padding-left: 10px;
    color: white;
}

.firstContent {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 2%;
}

.secondContent {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 30px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 1%;
}

.spacingGrid {
    padding-left: 6px;
    padding-right: 3px;
}

.contentbodyModel {
    flex: 1 1 auto;
    padding: 0.9375rem 20px;
    position: relative;
    -webkit-box-flex: 1;
}

.modelsTitle {
    color: #FFF !important;
    padding: 15px;
    margin-top: -20px;
    border-radius: 3px;
    margin-right: 1vw;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
}

.contentModel {
    width: 60%;
    margin-left: 30px;
    margin-right: 30px;
    color: white;

    @include xs {
        width: 90%;
        margin-left: 13px;
    }
}

.contentListNormal {
    width: 100%;
}

.contentListMini {
    width: 40%;

    @include xs {
        width: 100%;
    }
}

.nameStep {
    color: black;
    background-color: #10a544;
    // border: 2px solid #66CCCC;
    // margin-top: 10px;
    padding-left: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconContainerStep {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.role {
    border: 1px solid black;
}

.contentStepRole {
    width: 50px;
}

.iconArrow {
    transform: translate(-50%, 38%);
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 35px !important;
}
.iconPlus {
  font-size: 35px !important;
}

.stepAndRolesContainer {
    border: 1px solid #bdbdbd;
}

.iconModel {
    display: flex;
    justify-content: center;
    align-items: center;

    .addStepIconContainer {
        background: white;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 2px solid #bdbdbd;
        color: #bdbdbd;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        transform: translateX(-50%);

        .addStepIcon {
            font-size: 25px !important;
            cursor: pointer;
        }

        &:hover {
            border: 2px solid #10A544;
            color: #10A544;
        }
    }

    &:hover .addStepIconContainer {
        visibility: visible;
    }
}

.contentIconArrow {
    position: relative;
    width: 1px;
    border: 1px solid black;
    height: 50px;
    border-radius: 0 0 50px 50px;
}

.styleLine {
    line-height: 0px !important;
}

// :global(.react-bs-table) {
//     line-height: 0px !important;
// }

// :global(.react-bs-container-body) {
//     line-height: 0px !important;
// }

.iconAction :global(.material-icons) {
    line-height: 0px !important;
    display: inline;
    font-size: 16px;
}

.styleBtnRadio {
    display: flex;
    margin-left: 10px;
}

.contentBtnRadio {
    margin-left: 10px;
}

.contentModelStepOrientation{
    color: white;
    background-color: #10a544;
    padding-left: 10px;
}

.parent {
    width: 150px;
}


// ------------- Arbre -------------

:global(.rstm-toggle-icon) {
    display: inline-block;
}

:global(.rstm-toggle-icon-symbol) {
    width: 2rem;
    // height: 2rem; (===
    text-align: center;
    // line-height: 2rem;  (===
}

:global(.rstm-tree-item-group) {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid #ccc;
    text-align: left;
    width: 100%;
}

:global(.rstm-tree-item) {
    //padding: 0.75rem 1rem;
    cursor: pointer;
    color: #333;
    background: none;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    z-index: unset;
    position: relative;
}

:global(.rstm-tree-item--active) {
    color: white;
    background: #10a544;
    border-bottom: none;
}

:global(.rstm-tree-item--focused) {
    box-shadow: 0 0 5px 0 #222;
    z-index: 999;
}

:global(.rstm-search) {
    padding: 10px;
    border: none;
    width: 100%;
}

//Paramétrage dans liste modèle

.txtf {
    width: 480px !important;
}

.select,
.txtf {
    padding-bottom: 30px !important;
}

.contentList {
    position: relative;
    z-index: 9999;
    width: 500px;
    height: auto;
    overflow: auto;

}

.field {
    margin-bottom: 20px !important;
    margin-top: 0 !important;
}

.radioPadding {
    font-size: 12px !important;
}

.label {
    font-size: 14px;
}

.button {
    margin-top: 20px;
}

.iconContent {
    display: flex !important;
    align-items: center !important;

    .iconSelect {
        margin-right: 5px;
    }
}
.groupInputLabelzindex label{
  z-index: initial !important;
}