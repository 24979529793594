$defaultColor: #cdcdcd;
$finishColor: #10a544;
$enCoursColor: #39ACE6;


.workflowContainer {
  width: 100%;
  display: flex;
  padding: 10px;

  .stepsContainer {
    width: 100%;
    min-width: 0;
    margin-left: 30px;

    &:first-child.timelineLineResponse {
        width: 34px;
        position: absolute;
        height: 3px;
        top: 14px;
        left: -52px;
        background: $finishColor;
        color: white;
      }


      // &:last-child {
      //   width: 34px;
      //   position: absolute;
      //   height: 3px;
      //   top: 14px;
      //   left: -52px;
      //   background: $defaultColor;
      //   color: white;
      // }

    .stepContainer {
      position: relative;

      .timelineLine {
        width: 3px;
        position: absolute;
        height: 100%;
        top: 23px;
        left: -18px;

        .timelineProgress {
          width: 100%;
        }
      }

      //   .timelineLineHorizontal {
      //     width: 34px;
      //     position: absolute;
      //     height: 3px;
      //     top: 14px;
      //     left: -52px;

      //     .timelineProgress {
      //         width: 100%;
      //     }
      // }

      .step {
        margin-bottom: 7px;
        box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.75);

        $paddingHeader: 5px;

        .stepHeader {
          padding: $paddingHeader;
          border: 1px solid #d1d1d1;
          position: relative;
          cursor: pointer;

          .stepPuce {
            position: absolute;
            width: 20px;
            height: 20px;
            border: 1px solid $defaultColor;
            border-radius: 50%;
            left: -27px;
            top: $paddingHeader;
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;

            .iconStatut {
              font-size: inherit;
              font-size: 15px;
            }
          }

          .stepValidate {
            background: $finishColor;
            color: white;
            border-color: $finishColor;
          }

          .stepEnCours {
            background: $enCoursColor;
            color: white;
            border-color: $enCoursColor;
          }

          .stepTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .stepStatut {
              padding: 2px 4px;
              font-size: 10px;
              border: 1px solid white;
            }
          }
        }

        .tasksContainer {
          height: 0;
          opacity: 0;
          overflow: hidden;
          border-bottom: 1px solid #d1d1d1;
          border-left: 1px solid #d1d1d1;
          border-right: 1px solid #d1d1d1;
          padding-left: 15px;
          transition: 0.2s all ease-in-out;

          .tableHeader {
            th {
              padding: 5px;
              font-weight: bold;
              font-size: 12px;
              line-height: 1;
            }
          }

          .tableRowContent {
            td {
              padding: 3px;
              white-space: nowrap;
              font-size: 12px;
              color: inherit;
            }

            .iconStatutTask {
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .iconStatutFinish {
              color: $finishColor;
            }
          }

          .taskSelectable {
            cursor: pointer;

            &:hover {
              background: #81C7E4;
            }
          }

          .taskSelected {
            background: #1A4D66;
            color: white;
          }
        }
      }
    }

    .default {
      color: white;
      background: $defaultColor;
    }

    .finish {
      background: $finishColor;
      color: white;
    }

    .enCours {
      background: $enCoursColor;
      color: white;
    }

    .returned {
      background: orange;
      color: white;
    }
  }
}