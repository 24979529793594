.contentFormList {
  .noForms {
    padding: 50px 0;
    text-align: center;
  }

  .blockArray{
    margin-top: 15px;
    :global(.ms-FocusZone){
      z-index: 2;
      button{

      }
    }
    .formListArray,
    .convocationArray {
      z-index: 1;
      margin-top: -55px;
      .toolboxContainer {
        display: flex;
        justify-content: flex-end;

        .toolboxIcon {
          cursor: pointer;
        }
      }
    }
  }

  .loadingEditForm{
    margin-top: -35px;
    margin-bottom: 15px;
  }

  .convocationContainer {
    .accordionContainer {
      border-top: 1px solid #cacaca;
      border-left: 1px solid #cacaca;
      border-right: 1px solid #cacaca;
      border-bottom: 1px solid #cacaca;
      box-shadow: none !important;
      overflow: hidden;

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 1px solid transparent;
      }

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .accordionHeader {
        .accordionTitle {
          font-size: 14px;
          display: flex;
          align-items: center;
  
          .iconTitle {
            margin-right: 10px;
          }
        }
      }

      .accordionHeader:global(.Mui-expanded) {
        background: linear-gradient(60deg, rgb(38, 94, 102), rgb(30, 76, 81));
        color: white;

        .accordionIcon {
          color: white;
        }
      }

      .accordionContent {
        padding: 8px 16px 16px;
      }
    }

    .accordionContainer:global(.Mui-expanded) {
      margin: 0 !important;
    }
  }
}

.btnConvoc {
  margin-left: 10px;
  color: white;
  font-size: 15px;
  padding: 5px;
  height: 25px;
  border-radius: 5px;
  text-transform: initial !important;
}
.textEditor{
  input{
    padding: 5px
  }
}