$activeBackground:#DAD8D8;

.contentActions {
    .separator {
        font-size: 12px;
        color: #767676;
        display: block;
        width: 100%;
        border-bottom: 1px solid #b3b3b3;
        padding: 5px 10px;
    }

    .iconContent {
        // padding-left: 30%; 
        color: #1e4c51;
        font-size: 16px;
        padding: 15px;
        margin-top: 3px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
            margin-right: 15px;
        }

        &:hover {
            background-color: $activeBackground;
        }
    }




    .iconContentDisabled {
        cursor: default;

        &:hover {
            background-color: transparent;
        }
    }

    .hideText {
        display: none !important;
    }

    .textfield {
        padding-bottom: 8%;
        position: relative;
    }




    .editFieldContainer {
        padding: 0 10px 10px 10px;
        box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.05);
    }

    .activeBackground {
        background-color: $activeBackground;
        border-radius: 0 0 7px 7px;
    }

    .activeBackgroundTitle {
        background-color: $activeBackground;
        border-radius: 7px 7px 0 0;
    }

    .field {
        width: 100%;
        padding-bottom: 8px;
    }

}

.gridContainer{
    .iconCards {
      cursor: pointer;
      width: 100%;
      height: 70%;
      text-align: center;
      padding-top: 13px;
  
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .modelOrientation {
      border-bottom: 1px solid #c0c2c4;
      cursor: pointer;
      padding-top: 4px;
      height: 30px;
  
      /* &:hover {
        background-color: #e9ecef;
      } */
    }
    .cardsContainer {
        padding-left: 5px;
        display: flex;
        height: 104px;
        margin: 2px;
      }
}

.redWarning {
    color : darkred;
}

.formControlModelSelect {
    min-width: 200px !important;
    :global(.selected) {
      background-color: rgb(202, 255, 202);
    }
  }